export default [
  {
    key: "increasement",
    sortable: true,
    sortField: "id",
    label: "#",
    thClass: "w-50px",
  },
  {
    key: "code",
    sortable: true,
    sortField: "code",
    label: "field.code",
    stickyColumn: true,
    variant: "light",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "name",
    sortable: true,
    sortField: "name",
    label: "field.name",
    stickyColumn: true,
    variant: "light",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "status",
    label: "field.status",
  },
  {
    key: "createdAt",
    sortable: true,
    sortField: "id",
    label: "field.createdDate",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "createdBy",
    label: "field.createdBy",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "updatedAt",
    sortable: true,
    sortField: "updatedAt",
    label: "field.updatedDate",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "updatedBy",
    label: "field.updatedBy",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "actions",
    label: "field.action",
  },
];
